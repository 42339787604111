import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MuiTooltip, { TooltipProps as MuiTooltipProps } from "@material-ui/core/Tooltip";
import React from "react";

function arrowGenerator(color: string) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.95em",
      width: "2em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.95em",
      width: "2em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.95em",
      height: "2em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.95em",
      height: "2em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      position: "relative",
      maxWidth: 350,
      padding: theme.spacing(1),
    },
    arrow: {
      position: "absolute",
      fontSize: 6,
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
      },
    },
    popper: arrowGenerator(theme.palette.grey[700]),
  })
);

export const Tooltip: React.FC<MuiTooltipProps> = ({ ...rest }) => {
  const classes = useStyles();

  return <MuiTooltip classes={classes} arrow {...rest} />;
};

export default Tooltip;
